import { useEffect } from "react";
import { Grid, Button } from "@material-ui/core";

import { i18n } from "../../../i18n";
import { langs, socialMedia, sitemap } from "../../config";

import theme from "../../theme";
import { ptToVwConverter, mobileVwConverter } from "../../utils";

import Link from "../Link";
import { SNSImage, LangButton } from "../style";

import SNSFooterIcon from "../SNS/FooterIcon";
import i18nNext, { useTranslation } from "../../../i18n";
import useWindowSize from "../../utils/useWindowSize";
import { FooterTitleWrapper } from "../style/footer";

function FooterMenuTitle({ title, link }: { title: string; link: string }) {
  return (
    <Link href="/[page]" as={link}>
      <Button
        style={{ padding: 0, textAlign: "left", width: "100%" }}
        aria-label={title}
      >
        <FooterTitleWrapper>{title}</FooterTitleWrapper>
      </Button>
    </Link>
  );
}

export default ({ lang }: { lang: string }) => {
  if (!i18nNext.i18n.languages) {
    i18nNext.i18n.languages = ["ja", "en"];
  }
  const [t, _] = useTranslation("footer");
  const size = useWindowSize();

  useEffect(() => {
    const nonCurrentLang = langs.filter((each) => each !== lang)[0];
    const current = document.getElementById(lang);
    const nonCurrent = document.getElementById(nonCurrentLang);

    if (current && nonCurrent) {
      current.style.color = "#fff";
      nonCurrent.style.color = theme.palette.text.secondary;
    }
  }, [lang]);

  if (!size) return <p></p>;

  return (
    <div
      style={{
        width: "100vw",
        backgroundColor: "#000",
        color: "#fff",
      }}
    >
      <Link href="/">
        <img
          src="/assets/logo.png"
          alt="logo"
          style={{
            paddingLeft: "30px",
            width: "120px",
            height: "120px",
            objectFit: "contain",
          }}
          onContextMenu={(e) => {
            e.preventDefault();
            return false;
          }}
        />
      </Link>
      <div style={{ display: "flex" }}>
        <div style={{ width: size === "tablet" ? "100px" : "40px" }}></div>
        <Grid
          container
          style={{
            paddingRight: `${mobileVwConverter(20)}vw`,
          }}
        >
          {sitemap.map((item) => {
            return (
              <Grid
                item
                xs={size === "tablet" ? 4 : 6}
                style={{
                  marginBottom: "30px",
                }}
              >
                <FooterMenuTitle title={item.title} link={item.link} />
                {item.subMenus.map(
                  (subMenu: { title: string; link: string }) => {
                    // tslint:disable-next-line: prefer-const
                    let pathname = "/[page]";
                    // tslint:disable-next-line: prefer-const
                    let query = {
                      type: subMenu.title,
                    };
                    let href:
                      | {
                          pathname: string;
                          query: {
                            type: string;
                          };
                        }
                      | string = {
                      pathname,
                      query,
                    };
                    let as = `${item.link}?q=${subMenu.title}`;

                    if (item.link === "/naoya_aoki") {
                      if (subMenu.title === "profile") {
                        href = "/[page]";
                        as = "/naoya_aoki";
                      } else if (
                        subMenu.title === "activities" ||
                        subMenu.title === "choreography"
                      ) {
                        href.pathname = "/[page]/[category]";
                        as = `/naoya_aoki/works?q=${subMenu.title}`;
                      } else if (subMenu.title === "method") {
                        href = "/[page]/[category]";
                        as = "/naoya_aoki/method";
                      }
                    }

                    return (
                      <div
                        style={{
                          // fontSize: `${ptToVwConverter(12)}vw`,
                          // letterSpacing: `${ptToVwConverter(1.6)}vw`,
                          marginBottom: "20px",
                          width: "100%",
                        }}
                        key={subMenu.title}
                      >
                        <Link href={href} as={as ? as : undefined}>
                          <Button
                            style={{
                              padding: 0,
                              textAlign: "left",
                              letterSpacing: `1.6px`,
                              // fontSize: "12px",
                              width: "80%",
                              height: "28px",
                              color: "#b3b3b3",
                              borderRadius: 0,
                            }}
                            aria-label="submenu title"
                          >
                            <div
                              style={{
                                width: "100%",
                                fontSize: size === "tablet" ? "14px" : "13px",
                              }}
                            >
                              <div style={{ width: "fit-content" }}>
                                {t(subMenu.title) !== subMenu.title &&
                                  (t(subMenu.title) !== "Choreography/Coaching"
                                    ? t(subMenu.title)
                                    : "Choreography/\nCoaching")}
                              </div>
                            </div>
                          </Button>
                        </Link>
                      </div>
                    );
                  }
                )}
              </Grid>
            );
          })}
        </Grid>
      </div>

      <div
        style={{
          marginLeft: size === "tablet" ? "85px" : "30px",
          color: "#fff",
          marginBottom: `${mobileVwConverter(50)}vw`,
        }}
      >
        <LangButton id="ja" onClick={() => i18n.changeLanguage("ja")}>
          JP
        </LangButton>
        |
        <LangButton id="en" onClick={() => i18n.changeLanguage("en")}>
          EN
        </LangButton>
      </div>

      <div
        style={{
          marginLeft: size === "tablet" ? "85px" : "30px",
          paddingBottom: `${mobileVwConverter(60)}vw`,
        }}
      >
        {socialMedia.map((sns) => {
          return (
            <SNSFooterIcon
              Icon={() => (
                <SNSImage
                  src={`/assets/${sns.name}LogoWhite.png`}
                  alt={sns.name}
                  style={{
                    height: `${sns.footerHeight}px`,
                  }}
                />
              )}
              link={sns.link}
              width={sns.footerWidth}
            />
          );
        })}
      </div>
    </div>
  );
};
