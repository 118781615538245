import { SNSButton } from "../style";
import { ptToVwConverter } from "../../utils";

export default ({
  Icon,
  link,
  width,
}: {
  Icon: () => JSX.Element;
  link: string;
  width?: number;
}) => {
  return (
    <SNSButton
      style={
        width
          ? {
              width: `${ptToVwConverter(width)}vw`,
            }
          : undefined
      }
      onClick={() => {
        window.open(link, "_blank");
      }}
    >
      <Icon />
    </SNSButton>
  );
};
