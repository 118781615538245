import styled from "styled-components";
import { ptToVwConverter } from "../../utils";

export const FooterTitleWrapper = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: ${16 * 0.15}px;
  text-transform: uppercase;
  margin-bottom: ${ptToVwConverter(19)}vw;
  color: #fff;
  @media (max-width: 1024px) {
    letter-spacing: 0.1rem;
    margin-bottom: 30px;
  }
  @media (max-width: 1024px) and (min-width: 464px) {
    font-size: 14px;
  }
  @media (max-width: 464px) {
    font-size: 13px;
  }
`;
