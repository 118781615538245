import { useEffect } from "react";

// import { i18n } from "../../../i18n";
import { langs } from "../../config";

import Menu from "./Menu";
import theme from "../../theme";
import { NavBarContainer } from "../style";

export default ({ lang }: { lang: string }) => {
  const isWindowObject = typeof window === "object";

  useEffect(() => {
    const setColor = () => {
      const nonCurrentLang = langs.filter((each) => each !== lang)[0];
      const current = document.getElementById(lang);
      const nonCurrent = document.getElementById(nonCurrentLang);
      if (current && nonCurrent) {
        current.style.color = theme.palette.text.primary;
        nonCurrent.style.color = theme.palette.text.secondary;
      }
    };

    if (isWindowObject) {
      window.addEventListener("resize", setColor, { passive: true });

      return () => window.removeEventListener("resize", setColor);
    }
  }, []);

  return (
    <NavBarContainer container>
      <Menu lang={lang} />
    </NavBarContainer>
  );
};
