import { getBreakPoint } from "../../utils/useWindowSize";

import MobileMenu from "./MobileMenu";
import PCMenu from "./PCMenu";
import { useState, useEffect } from "react";

export default ({ lang }: { lang: string }) => {
  const isWindowClient = typeof window === "object";

  const [size, setWindowSize] = useState(
    isWindowClient ? getBreakPoint(window.innerWidth) : undefined
  );

  useEffect(() => {
    function setSize() {
      setWindowSize(getBreakPoint(window.innerWidth));
    }

    if (isWindowClient) {
      window.addEventListener("resize", setSize, { passive: true });

      return () => window.removeEventListener("resize", setSize);
    }
  }, []);

  // switch (size) {
  //   case "mobile":
  //     return <MobileMenu size={size} />;
  //   case "tablet":
  //     return <MobileMenu size={size} />;
  //   case "desktop":
  //     return <PCMenu />;
  //   default:
  //     return <div />;
  // }

  if (!size) return <p></p>;

  return size === "desktop" ? (
    <PCMenu />
  ) : (
    <MobileMenu size={size} lang={lang} />
  );
};
