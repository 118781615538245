import { useState, useEffect } from "react";
import {
  IconButton,
  Button,
  Drawer,
  List,
  ListItem,
  Divider,
} from "@material-ui/core";

import { i18n } from "../../../i18n";

import MenuIcon from "@material-ui/icons/Menu";
import { socialMedia, options } from "../../config";
import { SNSImage, LangButton } from "../style";
import Link from "../Link";
import { ptToVwConverter } from "../../utils";
import { langs } from "../../config";
import theme from "../../theme";
import styled from "styled-components";

const LogoImage = styled.img`
  width: 70%;
  opacity: 1;
  object-fit; cover;
  @media(min-width:500px) and (max-width:1024px) {
    width: 100%;
  }
  @media(min-width:360px)and(max-width:500px) {
    width: 120%;
  }
  @media(max-width:360px) {
    width: 70%;
  }
`;

export default ({ size, lang }: { size: string; lang: string }) => {
  const [open, setOpen] = useState(false);

  const setLang = () => {
    const nonCurrentLang = langs.filter((each) => each !== lang)[0];
    const current = document.getElementById(lang);
    const nonCurrent = document.getElementById(nonCurrentLang);
    if (current && nonCurrent) {
      current.style.color = "white";
      nonCurrent.style.color = theme.palette.text.secondary;
    }
  };

  const toggleDrawer = (status: boolean) => {
    setOpen(status);
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setLang();
      }, 100);
    }
  }, [open]);

  const list = () => (
    <div
      role="presentation"
      onClick={() => toggleDrawer(false)}
      onKeyDown={() => toggleDrawer(false)}
      style={{
        color: "white",
        textTransform: "uppercase",
        letterSpacing: "0.1rem",
        fontSize: "14px",
        lineHeight: "3em",
      }}
    >
      <List>
        <Link href="/" key="top">
          <ListItem button>top</ListItem>
        </Link>
        {options.map((option) => {
          let page;
          if (option.includes(" ")) {
            page = `${option.toLowerCase().split(" ").join("_")}`;
          } else {
            page = `${option.toLowerCase()}`;
          }
          const as = `/${page}`;

          return (
            <Link href="/[page]" as={as} key={option}>
              <ListItem button key={option}>
                {option}
              </ListItem>
            </Link>
          );
        })}
        <Link href="/[page]" as={"/contact"} key={"contact"}>
          <ListItem button key={"contact"}>
            contact
          </ListItem>
        </Link>
      </List>
      <Divider />
      <LangButton id="ja" onClick={() => i18n.changeLanguage("ja")}>
        JP
      </LangButton>
      |
      <LangButton id="en" onClick={() => i18n.changeLanguage("en")}>
        EN
      </LangButton>
      <Divider />
      {socialMedia
        .filter((sns) => sns.name === "vimeo")
        .map((sns) => {
          return (
            <Button
              key={sns.name}
              onClick={() => {
                window.open(sns.link, "_blank");
              }}
              style={{
                height: "50px",
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "20px",
              }}
            >
              <SNSImage
                src={`/assets/${sns.name}LogoWhite.png`}
                alt={sns.name}
                style={{
                  width: "40%",
                  maxHeight: "50px",
                  objectFit: "cover",
                }}
              />
            </Button>
          );
        })}
    </div>
  );

  return (
    <div
      style={{
        color: "white",
        backgroundColor: "black",
        display: "flex",
        justifyContent: "space-between",
        height: "55px",
        width: "100%",
      }}
    >
      <Drawer
        anchor="right"
        open={open}
        onClose={() => toggleDrawer(false)}
        transitionDuration={{ enter: 100, exit: 100 }}
      >
        {list()}
      </Drawer>
      <Button
        style={{
          paddingLeft: `${ptToVwConverter(50)}vw`,
          paddingTop: "10px",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          width: "120px",
        }}
      >
        <Link href="/" aria-label="logo">
          <LogoImage
            src="/assets/logo.png"
            alt="logo"
            onContextMenu={(e) => {
              e.preventDefault();
              return false;
            }}
          />
        </Link>
      </Button>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {socialMedia.map((sns) => {
          if (sns.name === "vimeo") return;

          return (
            <Button
              key={sns.name}
              onClick={() => {
                window.open(sns.link, "_blank");
              }}
              style={{
                height: "100%",
                minWidth: "40px",
              }}
            >
              <SNSImage
                src={`/assets/${sns.name}LogoWhite.png`}
                alt={sns.name}
                style={{
                  maxWidth: "25px",
                  maxHeight: "25px",
                }}
              />
            </Button>
          );
        })}
        <div
          style={{
            height: "100%",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={() => toggleDrawer(true)}
            style={{ height: "100%", width: "77px" }}
          >
            <MenuIcon style={{ width: "70%", height: "70%" }} />
          </IconButton>
        </div>
      </div>
    </div>
  );
};
