import { SNSFooterButton } from "../style";
import { ptToVwConverter, mobileVwConverter } from "../../utils";
import useWindowSize from "../../utils/useWindowSize";

export default ({
  Icon,
  link,
  width,
}: {
  Icon: () => JSX.Element;
  link: string;
  width?: number;
}) => {
  const size = useWindowSize();

  if (!size) return <p></p>;

  return (
    <SNSFooterButton
      style={
        width
          ? {
              width:
                size === "desktop"
                  ? `${ptToVwConverter(width)}vw`
                  : `${width}px`,
            }
          : undefined
      }
      onClick={() => {
        window.open(link, "_blank");
      }}
    >
      <Icon />
    </SNSFooterButton>
  );
};
