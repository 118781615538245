import useWindowSize from "../../utils/useWindowSize";

import PCFooter from "./PCMenu";
import MobileFooter from "./MobileMenu";

export default ({ lang }: { lang: string }) => {
  const size = useWindowSize() || "mobile";

  return size === "desktop" ? (
    <PCFooter lang={lang} />
  ) : (
    <MobileFooter lang={lang} />
  );
};
