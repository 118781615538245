import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { ptToVwConverter, scrollUp } from "../utils";
import useWindowSize from "../utils/useWindowSize";

const ImageContainer = styled.div`
  height: 40px;
  width: 40px;
  padding-bottom: 2px;
  border-radius: 40px;
  color: white;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default () => {
  const windowSize = useWindowSize();

  const isWindowClient = typeof window === "object";

  const [offset, setOffset] = useState(
    isWindowClient ? window.pageYOffset : undefined
  );

  useEffect(() => {
    const defineOffset = () => {
      setOffset(window.pageYOffset);
    };

    if (isWindowClient) {
      window.addEventListener("scroll", defineOffset, { passive: true });

      return () => window.removeEventListener("scroll", defineOffset);
    }
  }, [isWindowClient]);

  const scrollToTop = () => {
    scrollUp(0, windowSize);
  };

  return offset &&
    typeof innerWidth === "number" &&
    typeof innerHeight === "number" ? (
    <div
      style={{
        position: "fixed",
        bottom: `${ptToVwConverter(100)}vw`,
        right: `${ptToVwConverter(40)}vw`,
        animation: "fadeIn 700ms ease-in-out 1s both",
        cursor: "pointer",
        border: "solid 2px white",
        borderRadius: "50%",
      }}
    >
      <ImageContainer
        onClick={scrollToTop}
        onKeyPress={scrollToTop}
        role="button"
        aria-label="scroll button"
      >
        <ExpandLessIcon />
      </ImageContainer>
    </div>
  ) : (
    <></>
  );
};
