import { useEffect } from "react";
import { Grid, Button } from "@material-ui/core";

// import { i18n } from "../../../i18n";
import { langs, socialMedia, sitemap } from "../../config";

import theme from "../../theme";
import { ptToVwConverter } from "../../utils";

import Link from "../Link";
import { SNSImage } from "../style";

import SNSFooterIcon from "../SNS/FooterIcon";
import i18nNext, { useTranslation } from "../../../i18n";
import { FooterTitleWrapper } from "../style/footer";

function FooterMenuTitle({ title, link }: { title: string; link: string }) {
  return (
    <Link href="/[page]" as={link}>
      <Button
        style={{ padding: 0, textAlign: "left", width: "100%" }}
        aria-label={title}
      >
        <FooterTitleWrapper>{title}</FooterTitleWrapper>
      </Button>
    </Link>
  );
}

export default ({ lang }: { lang: string }) => {
  if (!i18nNext.i18n.languages) {
    i18nNext.i18n.languages = ["ja", "en"];
  }
  const [t, _] = useTranslation("footer");

  useEffect(() => {
    const nonCurrentLang = langs.filter((each) => each !== lang)[0];
    const current = document.getElementById(lang);
    const nonCurrent = document.getElementById(nonCurrentLang);

    if (current && nonCurrent) {
      current.style.color = theme.palette.text.primary;
      nonCurrent.style.color = theme.palette.text.secondary;
    }
  }, [lang]);

  // const eachMenuWidth = 180;
  const eachMenuWidth = 225;

  const callback = (menu: {
    title: string;
    link: string;
    subMenus: {
      title: string;
      link: string;
    }[];
  }) => (
    <div
      style={{
        height: `100%`,
        width: `${ptToVwConverter(eachMenuWidth)}vw`,
      }}
      key={menu.title}
    >
      <FooterMenuTitle title={menu.title} link={menu.link} />
      {menu.subMenus.map((subMenu: { title: string; link: string }) => {
        let pathname = "/[page]";
        let query = { type: subMenu.title };
        let href: { pathname: string; query: { type: string } } | string = {
          pathname,
          query,
        };
        let as = `${menu.link}?q=${subMenu.title}`;

        if (menu.link === "/naoya_aoki") {
          if (subMenu.title === "profile") {
            href = "/naoya_aoki";
            as = "";
          } else if (
            subMenu.title === "activities" ||
            subMenu.title === "choreography"
          ) {
            href.pathname = "/[pages]/[category]";
            as = `/naoya_aoki/works?q=${subMenu.title}`;
          } else if (subMenu.title === "method") {
            href = "/naoya_aoki/method";
            as = "";
          }
        }

        return (
          <div
            style={{
              // fontSize: `${ptToVwConverter(12)}vw`,
              // letterSpacing: `${ptToVwConverter(1.6)}vw`,
              marginBottom: `${ptToVwConverter(16)}vw`,
              width: "100%",
            }}
            key={subMenu.title}
          >
            <Link href={href} as={as ? as : undefined}>
              <Button
                style={{
                  padding: 0,
                  textAlign: "left",
                  letterSpacing: `1.6px`,
                  fontSize: "12px",
                  width: "80%",
                  color: "#b3b3b3",
                }}
                aria-label="submenu title"
              >
                <div style={{ width: "100%" }}>
                  {t(subMenu.title) !== subMenu.title && t(subMenu.title)}
                </div>
              </Button>
            </Link>
          </div>
        );
      })}
    </div>
  );

  return (
    <Grid
      container
      style={{
        width: "100%",
        height: `${ptToVwConverter(630)}vw`,
        backgroundColor: "#000",
        color: "white",
        position: "relative",
        bottom: 0,
      }}
    >
      <Grid item xs={12}>
        <div
          style={{
            margin: `${ptToVwConverter(60)}vw ${ptToVwConverter(
              50
            )}vw 0 ${ptToVwConverter(60)}vw`,
            display: "flex",
            height: "min-content",
          }}
        >
          <Link href="/">
            <img
              src="/assets/logo.png"
              alt="logo"
              style={{
                width: `${ptToVwConverter(90)}vw`,
                height: `${ptToVwConverter(90)}vw`,
                objectFit: "contain",
              }}
              onContextMenu={(e) => {
                e.preventDefault();
                return false;
              }}
            />
          </Link>
          <div
            style={{
              marginTop: `${ptToVwConverter(37)}vw`,
              marginLeft: `${ptToVwConverter(110)}vw`,
              // fontSize: `${ptToVwConverter(14)}vw`,
              fontSize: `14px`,
              display: "flex",
            }}
          >
            <div
              style={{
                width: `${ptToVwConverter(eachMenuWidth * 6)}vw`,
                height: `${ptToVwConverter(250 - 47)}vw`,
              }}
            >
              {/* 1-6 to first line */}
              <div
                style={{
                  height: `min-content`,
                  display: "flex",
                  marginBottom: "30px",
                }}
              >
                {sitemap.filter((_, i) => i < 6).map(callback)}
              </div>
              {/* 7-8 to second line */}
              <div style={{ display: "flex" }}>
                {sitemap.filter((_, i) => i >= 6).map(callback)}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            // marginTop: `${ptToVwConverter(250 - 37)}vw`,
            // position: "absolute",
            // right: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            height: "min-content",
            marginTop: `100px`,
            marginRight: `${ptToVwConverter(20)}vw`,
            marginBottom: `${ptToVwConverter(20)}vw`,
          }}
        >
          {socialMedia.map((sns) => {
            return (
              <SNSFooterIcon
                Icon={() => (
                  <SNSImage
                    src={`/assets/${sns.name}LogoWhite.png`}
                    alt={sns.name}
                    style={{
                      height: `${sns.footerHeight}px`,
                    }}
                  />
                )}
                link={sns.link}
                width={sns.footerWidth}
              />
            );
          })}
        </div>
      </Grid>
    </Grid>
  );
};
