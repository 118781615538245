import Link from "../Link";
import { i18n } from "../../../i18n";
import { options, socialMedia } from "../../config";

import { ptToVwConverter } from "../../utils";

import SNSIcon from "../SNS/Icon";

import {
  SubMenuContainer,
  MenuButton,
  PCWhiteBarContainer,
  IconContainer,
  PCBlackContainer,
  LangButton,
  IconImage,
  ContactButton,
  ContactButtonContainer,
  SNSImage,
} from "../style";

export default () => {
  return (
    <>
      <PCWhiteBarContainer item xs={12}>
        <SubMenuContainer>
          {socialMedia.map((sns) => {
            return (
              <SNSIcon
                Icon={() => (
                  <SNSImage
                    src={`/assets/${sns.name}LogoColor.png`}
                    alt={sns.name}
                    style={{
                      height: `${sns.height}px`,
                    }}
                  />
                )}
                link={sns.link}
                width={sns.width}
                key={sns.name}
              />
            );
          })}
          <LangButton id="ja" onClick={() => i18n.changeLanguage("ja")}>
            JP
          </LangButton>
          |
          <LangButton id="en" onClick={() => i18n.changeLanguage("en")}>
            EN
          </LangButton>
        </SubMenuContainer>
      </PCWhiteBarContainer>
      <PCBlackContainer>
        <IconContainer>
          <Link href="/" aria-label="logo">
            {/* <IconImage>ICON</IconImage> */}
            <IconImage
              src="/assets/logo.png"
              alt="logo"
              onContextMenu={(e) => {
                e.preventDefault();
                return false;
              }}
            />
          </Link>
        </IconContainer>
        <div style={{ position: "absolute", right: 0 }}>
          {options.map((option) => {
            let page;
            if (option.includes(" ")) {
              page = `${option.toLowerCase().split(" ").join("_")}`;
            } else {
              page = `${option.toLowerCase()}`;
            }
            const as = `/${page}`;
            return (
              <Link href="/[page]" as={as} key={option}>
                <MenuButton
                  className="menuTab"
                  onMouseEnter={(e) => {
                    const tab = e.currentTarget;

                    if (tab.children.length < 3) {
                      const copy = document.createElement("div");
                      copy.id = "temp";
                      copy.style.position = "absolute";
                      copy.style.width = "20px";
                      const originalHeight = tab.offsetHeight;
                      copy.style.height = `${originalHeight}px`;

                      copy.style.borderBottom = "solid 1px #fff";
                      tab.appendChild(copy);

                      let i = 1;
                      const duration = 100;
                      const sec = 10;

                      const id = global.setInterval(() => {
                        const actual = Number(copy.style.height.slice(0, -2));
                        const goal = 30;
                        const increment = (actual - goal) / (duration / sec);

                        if (Number(copy.style.height.slice(0, -2)) <= 30) {
                          clearInterval(id);
                        }
                        copy.style.height = `${originalHeight - i}px`;
                        i = i + increment;
                      }, sec);

                      tab.style.position = "relative";
                    }
                  }}
                  onMouseLeave={(e) => {
                    const tab = e.currentTarget;
                    if (tab.children[2].getAttribute("id") === "temp") {
                      tab.removeChild(tab.lastElementChild!);
                    }
                  }}
                >
                  {option}
                </MenuButton>
              </Link>
            );
          })}
          <ContactButtonContainer>
            <Link href="/[page]" as="/contact">
              <ContactButton>
                <img
                  src="/assets/email.svg"
                  alt="email"
                  style={{ width: `${ptToVwConverter(24)}vw` }}
                />
              </ContactButton>
            </Link>
          </ContactButtonContainer>
        </div>
      </PCBlackContainer>
    </>
  );
};
