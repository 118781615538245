import { FacebookProvider } from "react-facebook";

import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import theme from "../src/theme";

import "react-perfect-scrollbar/dist/css/styles.css";

import Layout from "../src/components/Layout";
import NavBar from "../src/components/NavBar";
import Footer from "../src/components/Footer";

import i18nNext, { appWithTranslation, useTranslation } from "../i18n";
import { ptToVwConverter } from "../src/utils";
import Scroll from "../src/components/Scroll";
import styled from "styled-components";

// FIXME: Temporary fix for twitter not being embedded properly
import "./TwitterTemporaryFix.css";

const app = ({ Component, pageProps }: { Component: any; pageProps: any }) => {
  if (!i18nNext.i18n.languages) {
    i18nNext.i18n.languages = ["ja", "en"];
  }
  const [_, i18n] = useTranslation();
  const lang = i18n.language;
  const appId = process.env.FACEBOOK_APP_ID;

  const MainWrapper = styled.div`
    @media (max-width: 1024px) {
      padding-top: 55px;
    }
    @media (min-width: 1024px) {
      padding-top: ${ptToVwConverter(120)}vw;
    }
  `;

  return (
    <Layout>
      <ThemeProvider theme={theme}>
        <FacebookProvider appId={appId}>
          <CssBaseline />
          <NavBar lang={lang} />
          <MainWrapper>
            <Component {...pageProps} lang={lang} />
          </MainWrapper>
          <Footer lang={lang} />
          <Scroll />
        </FacebookProvider>
      </ThemeProvider>
    </Layout>
  );
};

export default appWithTranslation(app);
